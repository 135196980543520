import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { SwipeableDrawer, Typography, Divider, Grid, Slide } from '@material-ui/core'
import { FilterSearch as FilterIcon, CloseCircle } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Button, IconButton } from '@refera/ui-web'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useRolePermission from '_hooks/use-role-permission'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { getServiceOrderFilterSelector } from '_modules/service-orders/selectors'
import { getServiceOrders, updateServiceOrderFilter } from '_modules/service-orders/actions'
import { userSelector } from '_modules/authentication/selectors'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'

import { FILTER_OPTIONS, FILTERS, CLEAN_FILTER_VALUES, FILTER_TABS, TABS } from './constants'
import FilterField from './filter-field'
import useStyles from './styles'
import { getClassification } from '_/modules/classification/actions'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
))

const ServiceOrderMainViewFilter = ({
  handleFilterOpen,
  open,
  defaultValues,
  setPage,
  isDelayed,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { isAdmin, isTradesman, isIntermediary } = useRolePermission()

  const [selectedTab, setSelectedTab] = useState(TABS.MAIN)
  const [agencyState, setAgencyState] = useState()

  const filters = useSelector(getServiceOrderFilterSelector)
  const user = useSelector(userSelector)

  const isDelayedActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DELAYED,
  })

  const tradesmanFilters = filter => filter.isTradesman
  const defaultFilters = filter => !filter.isAdmin
  const intermediaryFilters = filter => filter.isIntermediary

  const currentFilters = useMemo(() => {
    if (isAdmin) {
      return FILTER_TABS[selectedTab].filters
    }

    if (isTradesman) {
      return FILTERS.filter(tradesmanFilters)
    }

    if (isIntermediary) {
      return FILTERS.filter(intermediaryFilters)
    }

    return FILTERS.filter(defaultFilters)
  }, [isAdmin, isTradesman, isIntermediary, selectedTab])

  // TODO: Remove this and use directly the currentFields once Product Team confirm
  const fields = useMemo(() => {
    if (isDelayedActive) {
      return currentFilters
    }

    return currentFilters.filter(field => field.name !== FILTER_OPTIONS.SITUATION)
  }, [currentFilters, isDelayedActive])

  const methods = useForm({ defaultValues, shouldUnregister: false })

  const { handleSubmit, reset } = methods

  const cleanFilters = useCallback(() => {
    reset(CLEAN_FILTER_VALUES, {
      keepDirty: true,
    })
  }, [defaultValues])

  const onSubmit = useCallback(
    handleSubmit(formData => {
      const payload = isTradesman
        ? { ...filters, [FILTER_OPTIONS.TRADESMAN]: user.getServiceProviderId }
        : { ...filters, see_only_my_company_service_order: user.viewOnlyMyCompanyTickets }

      const formDataValues = formValues(formData)
      dispatch(
        getServiceOrders({
          ...payload,
          ...formDataValues,
          page: 1,
        })
      )

      dispatch(updateServiceOrderFilter({ ...filters, ...formDataValues, page: 0 }))
      setPage(0)
      handleFilterOpen()
    }),
    [dispatch, filters, isTradesman, user.id, setPage, user.viewOnlyMyCompanyTickets]
  )

  const handleTabClick = useCallback(event => {
    const { id } = event.currentTarget.dataset
    setSelectedTab(id)
  }, [])

  const fetchClassification = useCallback(async () => {
    await dispatch(
      getClassification({
        pageSize: 1000,
        agency: agencyState?.value ?? user?.agency,
      })
    )
  }, [dispatch, agencyState, user])

  useEffect(() => {
    fetchClassification()
  }, [agencyState])

  const renderTabs = useMemo(() => {
    if (!isAdmin) {
      return null
    }

    return (
      <Grid className={styles.tabFilters}>
        {Object.values(FILTER_TABS).map(tab => (
          <Grid
            key={tab.id}
            className={classNames(styles.tab, { [styles.selectedTab]: selectedTab === tab.id })}
            data-id={tab.id}
            onClick={handleTabClick}
          >
            <Typography>{tab.label}</Typography>
          </Grid>
        ))}
      </Grid>
    )
  }, [
    handleTabClick,
    isAdmin,
    selectedTab,
    styles.selectedTab,
    styles.tab,
    styles.tabFilters,
    selectedTab,
  ])

  useEffect(() => {
    if (isDelayed === true) fetchDelayedData(isDelayed)
  }, [isDelayed])

  const formValues = useCallback(dataToFormat => {
    const formDataValues = Object.keys(dataToFormat)?.reduce((acc, currentKey) => {
      const isCurrentValueAnObject =
        dataToFormat?.[currentKey]?.value !== undefined &&
        dataToFormat?.[currentKey]?.value !== null

      if (isCurrentValueAnObject) {
        return {
          ...acc,
          [currentKey]: dataToFormat[currentKey].value,
        }
      }

      return {
        ...acc,
        [currentKey]: dataToFormat?.[currentKey],
      }
    }, [])

    return formDataValues
  })

  const fetchDelayedData = useCallback(() => {
    const situationFilter = { situation: { value: 'delayed', label: 'Atrasados' } }
    const formattedDataValues = formValues(situationFilter)

    dispatch(updateServiceOrderFilter({ ...situationFilter, ...formattedDataValues, page: 0 }))
    dispatch(
      getServiceOrders({
        ...situationFilter,
        ...formattedDataValues,
        page: 0,
      })
    )
    setPage(1)
  }, [dispatch, filters, setPage])
  return (
    <SwipeableDrawer
      component="section"
      anchor="right"
      open={open}
      onClose={handleFilterOpen}
      onOpen={handleFilterOpen}
      className={styles.container}
      TransitionComponent={Transition}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Grid className={styles.header}>
            <Grid className={styles.headerTitle}>
              <Grid className={styles.iconWrapper}>
                <FilterIcon color={Theme.Colors.Primary.Base} />
              </Grid>
              <Typography component="h2" variant="h5" className={styles.title}>
                Filtros
              </Typography>
            </Grid>
            <IconButton onClick={handleFilterOpen} variant="ghost">
              <CloseCircle color={Theme.Colors.Primary.Base} className={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Divider className={styles.divider} />
          {renderTabs}
          <Grid className={styles.content}>
            {fields.map(filterField => {
              return (
                <FilterField
                  key={filterField.name}
                  setAgencyState={setAgencyState}
                  {...filterField}
                />
              )
            })}
          </Grid>

          <Grid className={styles.buttonsContainer}>
            <Button variant="ghost" color="primary" onClick={cleanFilters}>
              Limpar filtros
            </Button>
            <Button variant="primary" color="orange" type="submit">
              Aplicar filtro
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </SwipeableDrawer>
  )
}

ServiceOrderMainViewFilter.propTypes = {
  handleFilterOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  defaultValues: PropTypes.objectOf([PropTypes.string, PropTypes.number]).isRequired,
}

export default React.memo(ServiceOrderMainViewFilter)
