import React from 'react'
import { Grid, Tooltip, Typography } from '@material-ui/core'

import CategoryIndicator from '_components/category-indicator'
import Chip from '_components/chip'
import Priority from '_components/priority'
import Svg, { ICON } from '_components/svg'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import { SERVICE_ORDER_VALUES } from '_utils/constants'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'

import useStyles from './styles'
import Situation from './situation'

const getPriority = row => {
  return row.get(SERVICE_ORDER_VALUES.PRIORITY) === 3
}

const DefaultCell = ({ value, isUrgentPriority = false }) => {
  const styles = useStyles()

  return <p className={isUrgentPriority ? styles.boldText : styles.text}>{value}</p>
}

const IdCell = ({ value }) => {
  const styles = useStyles()

  return <div className={styles.idColumn}>{value}</div>
}

const MoreInformationCell = ({ id: rowId, getValue }) => {
  const styles = useStyles()

  const duplicatedFrom = getValue(rowId, 'duplicatedFrom')
  const serviceOrderDuplications = getValue(rowId, 'serviceOrderDuplications')

  if (duplicatedFrom || serviceOrderDuplications.size) {
    return <Svg className={styles.icon} type={ICON.DUPLICATE} />
  }

  return <span />
}

const CategoryCell = ({ value }) => {
  const styles = useStyles()
  const valueInJS = value.toJS()

  if (valueInJS.length) {
    const { name: firstCategoryName } = valueInJS[0]
    const extraCategoriesNames = valueInJS.slice(1).map(category => category?.name)

    return (
      <Grid container alignItems="center" className={styles.singleRowChip}>
        <Chip label={firstCategoryName} />
        {extraCategoriesNames?.length ? (
          <CategoryIndicator
            tooltipText={extraCategoriesNames?.join(', ')}
            label={`+${extraCategoriesNames?.length}`}
          />
        ) : null}
      </Grid>
    )
  }

  return <span />
}

const StepStatusCell = ({ value, row, isUrgentPriority = false }) => {
  const styles = useStyles()
  const isDelayedActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DELAYED,
  })

  return (
    <Grid container alignItems="center" spacing={8} wrap="nowrap">
      {isDelayedActive && (
        <Grid item alignItems="center" alignContent="center">
          <Situation {...row.toJS()} />
        </Grid>
      )}
      <Grid item className={styles.stepStatusContainer}>
        <Tooltip title={<Typography>{value}</Typography>}>
          <Typography className={isUrgentPriority ? styles.boldText : styles.text}>
            {value}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export const COLUMNS = {
  DEFAULT: [
    {
      field: SERVICE_ORDER_VALUES.ID,
      headerName: 'ID',
      sortable: true,
      width: 100,
      renderCell: IdCell,
    },
    {
      field: SERVICE_ORDER_VALUES.PRIORITY,
      headerName: 'Prioridade',
      sortable: true,
      width: 100,
      renderCell: ({ value }) => <Priority value={value} />,
    },
    {
      field: SERVICE_ORDER_VALUES.CATEGORY,
      headerName: 'Categoria',
      sortable: true,
      width: 500,
      renderCell: CategoryCell,
    },
    {
      field: SERVICE_ORDER_VALUES.AGENCY,
      headerName: 'Imobiliária',
      sortable: true,
      width: 200,
      renderCell: ({ value }) => <DefaultCell value={value?.get('name')} />,
    },
    {
      field: SERVICE_ORDER_VALUES.PROPERTY_CODE,
      headerName: 'Código do imóvel',
      sortable: true,
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      renderCell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      field: SERVICE_ORDER_VALUES.RESPONSIBLE,
      headerName: 'Responsável',
      sortable: true,
      width: 200,
      renderCell: ({ value }) => <DefaultCell value={value?.get('name')} />,
    },
    {
      field: SERVICE_ORDER_VALUES.STEP_STATUS,
      headerName: 'Etapa',
      sortable: true,
      width: 300,
      renderCell: StepStatusCell,
    },
    {
      field: SERVICE_ORDER_VALUES.MORE_INFORMATION,
      headerName: '',
      sortable: true,
      width: 50,
      renderCell: MoreInformationCell,
    },
  ],
  TRADESMAN: [
    {
      field: SERVICE_ORDER_VALUES.ID,
      headerName: 'ID',
      sortable: true,
      width: 100,
      renderCell: IdCell,
    },
    {
      field: SERVICE_ORDER_VALUES.PRIORITY,
      headerName: 'Prioridade',
      sortable: true,
      width: 100,
      renderCell: ({ value }) => <Priority value={value} />,
    },
    {
      field: SERVICE_ORDER_VALUES.CATEGORY,
      headerName: 'Categoria',
      sortable: true,
      width: 200,
      renderCell: CategoryCell,
    },
    {
      field: SERVICE_ORDER_VALUES.CONTACT_NAME,
      headerName: 'Solicitante',
      sortable: true,
      width: 200,
      renderCell: ({ value, row }) => (
        <DefaultCell value={value} isUrgentPriority={getPriority(row)} />
      ),
    },
    {
      field: SERVICE_ORDER_VALUES.LOCATION,
      headerName: 'Endereço',
      sortable: false,
      width: 200,
      flex: 1,
      renderCell: ({ value, row }) => (
        <DefaultCell value={value} isUrgentPriority={getPriority(row)} />
      ),
    },
    {
      field: SERVICE_ORDER_VALUES.TOTAL_PRICE,
      headerName: 'Valor do orçamento',
      sortable: true,
      width: 200,
      renderCell: ({ value, row }) => (
        <DefaultCell
          value={new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
            value
          )}
          isUrgentPriority={getPriority(row)}
        />
      ),
    },
    {
      field: SERVICE_ORDER_VALUES.STEP_STATUS,
      headerName: 'Etapa',
      sortable: false,
      width: 200,
      renderCell: ({ value, row }) => (
        <StepStatusCell value={value} row={row} isUrgentPriority={getPriority(row)} />
      ),
    },
  ],
}
