export function formatMessage({
  responsibleName,
  referaName,
  agencyName,
  address,
  serviceOrderDescription,
  totalBudget,
  paymentMethod,
  warranty,
  haveOrNotHaveTradesman,
}) {
  return `Olá, ${responsibleName}! Meu nome é ${referaName} da Refera!

Somos responsáveis pelas manutenções da imobiliária ${agencyName} onde seu imóvel está alugado.

Referente ao seu imóvel localizado em ${address}. Sua inquilina entrou em contato com a manutenção informando o seguinte: ${serviceOrderDescription}

${haveOrNotHaveTradesman} *(O laudo técnico com fotos se encontra no pdf em anexo.)*

O valor para realizar o serviço conosco, já com material e mão de obra é R$ ${totalBudget}, ${paymentMethod} e descontado no repasse do aluguel.

Este serviço tem nota fiscal e ${warranty}.

*Podemos prosseguir com a manutenção? Por gentileza nos informe assim que possível para podermos avisar ao locatário.*

Fico no aguardo e agradeço desde já!`
}

export function formatMessageIntermediary({
  responsibleName,
  referaName,
  agencyName,
  address,
  serviceOrderDescription,
  totalBudget,
  paymentMethod,
  warranty,
  haveOrNotHaveTradesman,
}) {
  return `Olá, ${responsibleName}! Meu nome é ${referaName} da ${agencyName}!

Somos responsáveis pelas manutenções da imobiliária ${agencyName} onde seu imóvel está alugado.

O inquilino do seu imóvel localizado em ${address} entrou em contato informando o seguinte caso: ${serviceOrderDescription}

${haveOrNotHaveTradesman} *O laudo técnico da solução com fotos se encontra no pdf em anexo.*

O valor para realizar o serviço conosco, já com material e mão de obra é R$ ${totalBudget}, ${paymentMethod} e descontado no repasse do aluguel.

Este serviço tem nota fiscal e ${warranty}.

Podemos prosseguir com o serviço? Faremos com velocidade e qualidade para evitar a insatisfação do seu inquilino.`
}
