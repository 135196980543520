import React, { useState, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Loader, Dialog as ReferaDialog, Typography } from '@refera/ui-web'
import { Danger as InfoDangerIcon } from '@refera/ui-icons'
import { DateTimePicker } from '_components/inputs'

import useStyles from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { serviceOrderCurrentSelector } from '_modules/service-orders/selectors'
import moment from 'moment'
import { registerExecution } from '_/modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'
import { getStepStatusLog } from '_/modules/budget/actions'
import { useNavigate } from '@reach/router'
import { ROUTES } from '_/utils/constants'

const defaultDialog = {
  isOpen: false,
  subject: '',
  icon: InfoDangerIcon,
  iconType: 'info',
  description: '',
  labelApprove: 'Ok, entendi',
  onApprove: () => {},
}

export function FinishServiceModal({
  open,
  onClose,
  onCancel,
  executionProofs,
  requesterRatingFeature,
}) {
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { showToast } = useToast()
  const [dialog, setDialog] = useState(defaultDialog)

  const navigate = useNavigate()

  const styles = useStyles()

  const dispatch = useDispatch()
  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const serviceOrderExecutedAt = serviceOrder?.get('executedAt')

  const defaultValues = {
    executedAt: serviceOrderExecutedAt || null,
  }

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })

  const saveServiceExecution = useCallback(
    async executedAt => {
      setLoading(true)
      dispatch(
        registerExecution(serviceOrder?.get('id'), {
          executedAt: executedAt.toISOString(),
        })
      )
        .then(() => {
          showToast({ type: 'success' })
          setSaveDisabled(false)
          dispatch(getStepStatusLog(serviceOrder?.get('id'))).then(() => {
            setLoading(false)
            if (serviceOrder?.get('requesterRatingFeature'))
              navigate(`${ROUTES.SEND_RATING_LINK}/${serviceOrder?.get('id')}`)
            onClose()
          })
        })
        .catch(() => {
          setSaveDisabled(false)
          setLoading(false)
          showToast({ type: 'error' })
        })
    },
    [onClose, serviceOrder?.get('id')]
  )

  const handleSaveButtonClick = useCallback(
    async data => {
      if (!methods.formState.isDirty) {
        onClose()
        return
      }

      setSaveDisabled(true)
      const executedAt = moment(data.executedAt)
      const now = moment()

      if (executedAt.isAfter(now)) {
        setSaveDisabled(false)
        showToast({
          type: 'error',
          message: 'A data e hora devem ser no passado.',
        })
        return
      }

      if (!executionProofs?.length && requesterRatingFeature) {
        setDialog({
          isOpen: true,
          iconType: 'warning',
          icon: InfoDangerIcon,
          labelApprove: 'Ok, entendi',
          subject: 'Atenção!',
          description:
            'Você não pode finalizar um serviço sem enviar fotos. Envie fotos para depois finalizar o serviço.',
          onApprove: () => setDialog(prev => ({ ...prev, isOpen: false })),
        })
        setSaveDisabled(false)

        return
      }

      await saveServiceExecution(executedAt)
    },
    [methods.formState.isDirty, saveServiceExecution, onClose, requesterRatingFeature]
  )

  return (
    <>
      <Dialog open={open} className={styles.dialog} keepMounted={false}>
        <div key="finishServiceModalHeader" className={styles.header}>
          <DialogTitle className={styles.title}>Finalizar serviço</DialogTitle>
        </div>
        <DialogContent className={styles.content}>
          <FormProvider {...methods}>
            <form
              key="finishServiceModalForm"
              id="finishServiceModalForm"
              className={styles.form}
              onSubmit={e => e.preventDefault()}
              noValidate
            >
              <Typography component="p" className={styles.p}>
                Ao salvar, você está informando a Refera que o serviço foi finalizado. Agora será
                necessário que você envie o link de avaliação do seu serviço para o cliente.
              </Typography>
              <DateTimePicker name="executedAt" label="Data do serviço finalizado" required />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={onCancel} variant="secondary" color="red" className={styles.button}>
            Cancelar
          </Button>
          <Button
            onClick={methods.handleSubmit(handleSaveButtonClick)}
            className={styles.button}
            type="button"
            disabled={saveDisabled || loading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {dialog?.isOpen && (
        <ReferaDialog
          open={dialog?.isOpen}
          type={dialog?.iconType}
          icon={dialog?.icon}
          subject={dialog?.subject}
          description={dialog?.description}
          labelApprove={dialog?.labelApprove}
          onApprove={dialog?.onApprove}
          labelCancel={dialog?.labelCancel}
          onCancel={dialog?.onCancel}
        />
      )}
      <Loader hasBackdrop open={loading} label="Aguarde..." />
    </>
  )
}
