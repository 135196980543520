import React, { useMemo, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { TextField, Tooltip, Typography } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { agenciesResultSelector } from '_modules/agency/selectors'
import { getSortedCitiesOptionsSelector } from '_modules/utils/selectors'
import { getStepStatusFilterSelector } from '_modules/service-orders/selectors'
import { classificationsSelector } from '_modules/classification/selectors'
import Textfield from '_components/textfield'
import { AGENCY_TYPES_OPTIONS, DEFAULT_ALL_OPTION } from '_utils/constants'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'

import { FILTER_OPTIONS, SITUATION_OPTIONS } from './constants'
import useStyles from './styles'
import { userAgentsSelector } from '_/modules/responsible/selectors'

const OPTION_PAUSED_SITUATION = {
  value: 'paused',
  label: 'Pausados',
}

const InputTooltip = memo(({ text }) => {
  const styles = useStyles()

  if (!text) {
    return null
  }

  return (
    <Tooltip
      arrow
      disableFocusListener
      disableTouchListener
      title={<Typography className={styles.tooltipText}>{text}</Typography>}
      placement="bottom-end"
    >
      <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
    </Tooltip>
  )
})

const FilterField = ({ isTextfield, helperText, name, label, setAgencyState }) => {
  const styles = useStyles()
  const { control, register } = useFormContext()

  const agencies = useSelector(agenciesResultSelector)
  const cityOptions = useSelector(getSortedCitiesOptionsSelector)
  const userResponsible = useSelector(userAgentsSelector)
  const stepStatusFilter = useSelector(getStepStatusFilterSelector)
  const classificationFilter = useSelector(classificationsSelector)
  const isPauseServiceOrderActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
  })

  const agencyOptions = useMemo(() => {
    if (!agencies?.length) {
      return []
    }

    const formattedAgencies = agencies
      .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase() ? 1 : -1))
      .map(agency => ({
        value: agency.id,
        label: agency.name,
      }))

    return [...AGENCY_TYPES_OPTIONS, ...formattedAgencies]
  }, [agencies])

  const responsibleOptions = useMemo(
    () =>
      userResponsible
        ?.map(responsible => ({
          value: responsible.id,
          label: responsible.name,
        }))
        .filter(responsible => responsible.label !== ''),
    [userResponsible]
  )

  const stepStatusOptions = useMemo(
    () =>
      stepStatusFilter?.map(stepStatus => ({
        value: stepStatus.name,
        label: stepStatus.desc,
      })),
    [stepStatusFilter]
  )

  const classificationOptions = useMemo(
    () =>
      classificationFilter
        ?.map(classification => {
          const option = {
            value: classification.id,
            label: classification.classificationName,
          }

          return option
        })
        ?.filter(classification => classification),
    [classificationFilter]
  )

  const serviceOrderStatusFields = [
    {
      value: 'isActive',
      label: 'Chamados ativos',
    },
    {
      value: 'service_order_finished',
      label: 'Chamados finalizados',
    },
    {
      value: 'service_order_canceled',
      label: 'Chamados cancelados',
    },
  ]

  const emergencyOptions = [
    {
      value: true,
      label: 'Sim',
    },
    {
      value: false,
      label: 'Não',
    },
  ]

  const priorityOptions = [
    {
      value: 'emergency',
      label: 'Emergência',
    },
    {
      value: 'urgent',
      label: 'Urgente',
    },
    {
      value: 'high',
      label: 'Alta',
    },
    {
      value: 'average',
      label: 'Média',
    },
  ]

  const options = useMemo(() => {
    switch (name) {
      case FILTER_OPTIONS.AGENCY:
        return [DEFAULT_ALL_OPTION, ...agencyOptions]
      case FILTER_OPTIONS.SERVICEORDER_STATUS:
        return [DEFAULT_ALL_OPTION, ...serviceOrderStatusFields]
      case FILTER_OPTIONS.PRIORITY:
        return [DEFAULT_ALL_OPTION, ...priorityOptions]
      case FILTER_OPTIONS.CITY:
        return [DEFAULT_ALL_OPTION, ...cityOptions]
      case FILTER_OPTIONS.IS_EMERGENCY:
        return [DEFAULT_ALL_OPTION, ...emergencyOptions]
      case FILTER_OPTIONS.RESPONSIBLE:
        return [DEFAULT_ALL_OPTION, ...responsibleOptions]
      case FILTER_OPTIONS.STEP_STATUS:
        return stepStatusOptions ? [DEFAULT_ALL_OPTION, ...stepStatusOptions] : [DEFAULT_ALL_OPTION]
      case FILTER_OPTIONS.SITUATION:
        return isPauseServiceOrderActive
          ? [DEFAULT_ALL_OPTION, ...SITUATION_OPTIONS, OPTION_PAUSED_SITUATION]
          : [DEFAULT_ALL_OPTION, ...SITUATION_OPTIONS]
      case FILTER_OPTIONS.CLASSIFICATION:
        return classificationOptions
          ? [DEFAULT_ALL_OPTION, ...classificationOptions]
          : [DEFAULT_ALL_OPTION]
      default:
        return null
    }
  }, [
    agencyOptions,
    cityOptions,
    isPauseServiceOrderActive,
    name,
    classificationOptions,
    responsibleOptions,
    stepStatusOptions,
    priorityOptions,
  ])

  const endAdornment = <InputTooltip text={helperText} />

  const getOptionLabel = useCallback(
    option => {
      if (typeof option === 'string' || typeof option === 'number') {
        return options?.find(opt => opt?.value === option)?.label || ''
      }

      return option?.label || option || ''
    },
    [options]
  )

  const getOptionSelected = useCallback(
    (option, currentValue) =>
      String(option?.value) === String(currentValue?.value) ||
      String(option?.value) === currentValue,
    []
  )

  const renderAutocompleteController = useCallback(
    field => {
      return (
        <Autocomplete
          {...field}
          id={name}
          options={options}
          getOptionSelected={getOptionSelected}
          multiple={false}
          getOptionLabel={getOptionLabel}
          renderInput={params => <TextField {...params} />}
          onChange={(event, newValue) => {
            if (name === 'agency') setAgencyState(newValue)
            field.onChange(newValue)
          }}
        />
      )
    },
    [getOptionLabel, getOptionSelected, name, options]
  )

  if (isTextfield) {
    return (
      <Textfield
        name={name}
        className={styles.textfield}
        endAdornment={endAdornment}
        label={label}
        inputRef={register}
        InputLabelProps={{
          classes: {
            root: styles.labelTextfield,
          },
        }}
      />
    )
  }

  return (
    <>
      <Typography className={styles.label}>{label}</Typography>
      <Controller control={control} name={name} as={renderAutocompleteController} />
    </>
  )
}

FilterField.propTypes = {
  isTextfield: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

FilterField.defaultProps = {
  isTextfield: false,
  helperText: undefined,
}

export default React.memo(FilterField)
