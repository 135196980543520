import React, { useEffect } from 'react'
import * as Modal from '_components/modal/generic-modal'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import MultilineInput from '_/components/inputs/MultilineInput'
import { FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material'
import useStyles from './styles'
import { getRatingRequester } from '_/modules/messages/actions'
import { useDispatch } from 'react-redux'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'

export function RateServiceModal({ open, onClose, onSubmit, ratingDetails }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'all',
  })
  const negativeFeedbackDisabled = !!ratingDetails || methods.watch('solution_approved') !== 'Não'

  useEffect(() => {
    if (negativeFeedbackDisabled) methods.clearErrors('negative_feedback')
  }, [negativeFeedbackDisabled])

  useEffect(() => {
    if (ratingDetails) {
      dispatch(getRatingRequester(ratingDetails))
        .then(res => {
          methods.setValue('solution_approved', res.solutionApproved ? 'Sim' : 'Não')
          methods.setValue('negative_feedback', res.negativeFeedback)
        })
        .catch(error => {
          console.error('Erro ao buscar dados de avaliações:', error)
        })
    }
  }, [dispatch, ratingDetails])

  return (
    <Modal.Root open={open} onClose={onClose}>
      <Modal.TitleModal title="Avaliar serviço pelo morador" />
      <Modal.Content className={styles.content}>
        <Modal.Text style={{ textAlign: 'left', lineHeight: '2.5rem' }}>
          Indique se a Refera aprova ou reprova o serviço do prestador pelo morador e descreva os
          motivos que justificam a avaliação.
        </Modal.Text>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={e => e.preventDefault()}>
            <Controller
              control={methods.control}
              name="solution_approved"
              defaultValue={null}
              rules={{ required: true }}
              render={({ onBlur, onChange, value }) => (
                <div className={styles.radioFormGroup}>
                  <Typography
                    component="h5"
                    className={`${styles.inputLabel} ${styles.labelRequired}`}
                  >
                    O serviço foi executado corretamente e tem a sua aprovação?
                  </Typography>
                  <RadioGroup
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={styles.radio}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      key="sim"
                      label="Sim"
                      value="Sim"
                      color="success"
                      disabled={!!ratingDetails}
                      className={styles.formControlLabel}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      key="não"
                      label="Não"
                      value="Não"
                      color="success"
                      disabled={!!ratingDetails}
                      className={styles.formControlLabel}
                    />
                  </RadioGroup>
                  <FormHelperText
                    className={styles.errorMessage}
                    error={!!methods.errors?.solution_approved}
                  >
                    {methods.errors?.solution_approved ? 'Esse campo é obrigatório' : ''}
                  </FormHelperText>
                </div>
              )}
            />
            <div>
              <MultilineInput
                defaultValue=""
                name="negative_feedback"
                label="Descreva o motivo"
                minRows={8}
                labelClasses={`${styles.inputLabel} ${
                  methods.errors?.negative_feedback ? styles.overrideInputLabelOnError : ''
                }`}
                required={!negativeFeedbackDisabled}
                disabled={negativeFeedbackDisabled}
                helperText={`${methods.watch('negative_feedback')?.length}/255`}
                inputClasses={styles.overrideHelperText}
                inputProps={{ maxLength: 255 }}
              />
              <ConfidentialDataWarning style={{ marginTop: 10 }} />
            </div>
          </form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        {ratingDetails ? (
          <Modal.ButtonRed onClick={onClose}>Fechar</Modal.ButtonRed>
        ) : (
          <>
            <Modal.ButtonRed onClick={onClose}>Cancelar</Modal.ButtonRed>
            <Modal.ButtonFullBlue onClick={methods.handleSubmit(onSubmit)}>
              Confirmar
            </Modal.ButtonFullBlue>
          </>
        )}
      </Modal.Actions>
    </Modal.Root>
  )
}
