import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, spacing }) => ({
  title: {
    fontSize: spacing(20),
    lineHeight: spacing(20),
    fontWeight: '500',
    fontFamily: 'DM Sans',
    color: palette.primary.main,
    padding: 0,
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: spacing(480),
    },
  },
  p: {
    fontSize: `${spacing(14)} !important`,
    lineHeight: `${spacing(20)} !important`,
    color: '#696969',
    textAlign: 'left',
    letterSpacing: '0 !important',
  },
  content: {
    padding: spacing(16, 24),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(16),
  },
  button: {
    width: spacing(90),
    padding: `${spacing(10)} auto`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(16, 24),
  },

  actions: {
    padding: spacing(12, 24, 16),
  },
}))
