export const FILTER_OPTIONS = {
  AGENCY: 'agency',
  CITY: 'city',
  STEP_STATUS: 'stepStatus',
  FULL_ADDRESS: 'fullAddress',
  MESSAGE: 'message',
  PROPERTY_CODE: 'code',
  REQUESTER_DATA: 'requesterData',
  RESPONSIBLE: 'responsible',
  SERVICE_ORDER_ID: 'serviceOrder',
  TRADESMAN: 'tradesman',
  CLASSIFICATION: 'classification',
  SITUATION: 'situation',
  SERVICEORDER_STATUS: 'statusServiceOrder',
  IS_EMERGENCY: 'emergency',
  PRIORITY: 'priority',
}

export const SITUATION_OPTIONS = [
  {
    value: 'active',
    label: 'Ativos',
  },
  {
    value: 'delayed',
    label: 'Atrasados',
  },
]

export const FILTER_OPTIONS_LABEL = {
  [FILTER_OPTIONS.AGENCY]: 'Por imobiliária',
  [FILTER_OPTIONS.IS_EMERGENCY]: 'Cliente considera emergência',
  [FILTER_OPTIONS.CITY]: 'Por cidade do imóvel',
  [FILTER_OPTIONS.STEP_STATUS]: 'Por etapa',
  [FILTER_OPTIONS.SERVICEORDER_STATUS]: 'Status do chamado',
  [FILTER_OPTIONS.FULL_ADDRESS]: 'Por endereço',
  [FILTER_OPTIONS.MESSAGE]: 'Comentário contenha',
  [FILTER_OPTIONS.PROPERTY_CODE]: 'Por código do imóvel',
  [FILTER_OPTIONS.REQUESTER_DATA]: 'Por solicitante',
  [FILTER_OPTIONS.RESPONSIBLE]: 'Responsável pelo chamado',
  [FILTER_OPTIONS.SERVICE_ORDER_ID]: 'Por ID do chamado',
  [FILTER_OPTIONS.TRADESMAN]: 'Por prestador',
  [FILTER_OPTIONS.CLASSIFICATION]: 'Por classificação',
  [FILTER_OPTIONS.SITUATION]: 'Por situação',
  [FILTER_OPTIONS.PRIORITY]: 'Por priorididade',
}

export const CLEAN_FILTER_VALUES = {
  [FILTER_OPTIONS.AGENCY]: '',
  [FILTER_OPTIONS.CITY]: '',
  [FILTER_OPTIONS.FULL_ADDRESS]: '',
  [FILTER_OPTIONS.MESSAGE]: '',
  [FILTER_OPTIONS.PROPERTY_CODE]: '',
  [FILTER_OPTIONS.REQUESTER_DATA]: '',
  [FILTER_OPTIONS.RESPONSIBLE]: '',
  [FILTER_OPTIONS.SERVICE_ORDER_ID]: '',
  [FILTER_OPTIONS.STEP_STATUS]: '',
  [FILTER_OPTIONS.SERVICEORDER_STATUS]: '',
  [FILTER_OPTIONS.TRADESMAN]: '',
  [FILTER_OPTIONS.CLASSIFICATION]: '',
  [FILTER_OPTIONS.SITUATION]: '',
  [FILTER_OPTIONS.IS_EMERGENCY]: '',
  [FILTER_OPTIONS.PRIORITY]: '',
}

export const FILTERS = [
  {
    name: FILTER_OPTIONS.STEP_STATUS,
    isTradesman: true,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.PRIORITY,
    isTradesman: true,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.SITUATION,
    isTradesman: true,
  },
  {
    name: FILTER_OPTIONS.SERVICE_ORDER_ID,
    isTextfield: true,
    isTradesman: true,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.PROPERTY_CODE,
    isTextfield: true,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.IS_EMERGENCY,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.CITY,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.REQUESTER_DATA,
    isTextfield: true,
    isTradesman: true,
    helperText: 'Busque por nome, email, telefone ou CPF/CNPJ',
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.RESPONSIBLE,
    isTradesman: false,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.TRADESMAN,
    isTextfield: true,
    helperText: 'Busque por nome da empresa, responsável, telefone e e-mail do prestador',
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.CLASSIFICATION,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.AGENCY,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.FULL_ADDRESS,
    isTextfield: true,
    isTradesman: true,
    helperText: 'Busque por rua, número, bairro, cidade, estado ou complemento',
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.MESSAGE,
    isTextfield: true,
    isIntermediary: true,
  },
  {
    name: FILTER_OPTIONS.SERVICEORDER_STATUS,
    isIntermediary: true,
  },
].map(value => ({
  ...value,
  label: FILTER_OPTIONS_LABEL[value.name],
}))

export const MAIN_FILTERS = [
  {
    name: FILTER_OPTIONS.STEP_STATUS,
    isTradesman: true,
  },
  {
    name: FILTER_OPTIONS.PRIORITY,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.SITUATION,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.SERVICE_ORDER_ID,
    isTextfield: true,
    isTradesman: true,
  },
  {
    name: FILTER_OPTIONS.PROPERTY_CODE,
    isTextfield: true,
  },
  {
    name: FILTER_OPTIONS.TRADESMAN,
    isTextfield: true,
    helperText: 'Busque por nome da empresa, responsável, telefone e e-mail do prestador.',
  },
  {
    name: FILTER_OPTIONS.AGENCY,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.CLASSIFICATION,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.MESSAGE,
    isTextfield: true,
  },
  {
    name: FILTER_OPTIONS.SERVICEORDER_STATUS,
  },
].map(value => ({
  ...value,
  label: FILTER_OPTIONS_LABEL[value.name],
}))

export const SECONDARY_FILTERS = [
  {
    name: FILTER_OPTIONS.IS_EMERGENCY,
  },
  {
    name: FILTER_OPTIONS.CITY,
  },
  {
    name: FILTER_OPTIONS.REQUESTER_DATA,
    isTextfield: true,
    isTradesman: true,
    helperText: 'Busque por nome, email, telefone ou CPF/CNPJ',
  },
  {
    name: FILTER_OPTIONS.RESPONSIBLE,
    isAdmin: true,
  },
  {
    name: FILTER_OPTIONS.FULL_ADDRESS,
    isTextfield: true,
    isTradesman: true,
    helperText: 'Busque por rua, número, bairro, cidade, estado ou complemento',
  },
].map(value => ({
  ...value,
  label: FILTER_OPTIONS_LABEL[value.name],
}))

export const TABS = {
  MAIN: 'main',
  SECONDARY: 'secondary',
}

export const FILTER_TABS = {
  [TABS.MAIN]: {
    id: TABS.MAIN,
    label: 'Filtros principais',
    filters: MAIN_FILTERS,
  },
  [TABS.SECONDARY]: {
    id: TABS.SECONDARY,
    label: 'Mais filtros',
    filters: SECONDARY_FILTERS,
  },
}
