import { useParams } from '@reach/router'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useToggle from '_hooks/use-toggle'
import { sendBudgetEmail } from '_modules/budget/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { getHistoryLogs } from '_modules/history-logs/actions'
import Button from '_components/button'
import Svg, { ICON } from '_components/svg'

import ConfirmModal from '../modals/confirm-modal'
import WarningModal from '../modals/warning-modal'

import useStyles from './styles'
import { Alert, Toast } from '@refera/ui-web'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const SendBudgetByEmailButton = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const { showToast } = useToast()
  const { checkUserPermission } = useRolePermission()

  const budget = useSelector(getBudgetByIdSelector(budgetId))

  const [isNotRevised, setIsNotRevised] = useState(false)
  const [isEmptyFields, setIsEmptyFields] = useToggle()
  const [isRefused, setIsRefused] = useToggle()
  const [isEmailLoading, onToggleEmailLoading] = useToggle(false)

  const sendEmail = async () => {
    await dispatch(sendBudgetEmail(serviceOrderId, budgetId))
      .then(() => {
        dispatch(getHistoryLogs(serviceOrderId))
        showToast({ type: 'success' })
        onToggleEmailLoading()
      })
      .catch(error => {
        const errorMessage = formatErrorMessage(error)
        showToast({ message: errorMessage, type: 'error' })
        onToggleEmailLoading()
      })
  }

  const handleSendBudgetByEmail = useCallback(() => {
    const budgetPayer = budget.budgetPayer.toJS()[0]
    const budgetRevised = budget.isRevised || budget.proposeOpened

    onToggleEmailLoading()

    if (!budgetRevised) {
      setIsNotRevised(true)
      return
    }

    if (budgetRevised && !budgetPayer?.personPayingEmail) {
      setIsEmptyFields()
      return
    }

    if (budget.refused) {
      setIsRefused()
      return
    }

    if (budgetRevised && budgetPayer?.personPayingEmail) {
      sendEmail()
    }
  }, [onToggleEmailLoading, budget, sendEmail])

  const handleSendRefusedBudgetByEmail = useCallback(async () => {
    await sendEmail()
    setIsRefused()
  }, [sendEmail, setIsRefused])

  const handleIsNotRevisedModal = useCallback(() => {
    setIsNotRevised(false)
    onToggleEmailLoading()
  }, [onToggleEmailLoading, setIsNotRevised])

  const handleIsEmptyModal = useCallback(() => {
    setIsEmptyFields()
    onToggleEmailLoading()
  }, [onToggleEmailLoading, setIsEmptyFields])

  const handleIsRefusedModal = useCallback(() => {
    setIsRefused()
    onToggleEmailLoading()
  }, [onToggleEmailLoading, setIsRefused])

  return (
    <>
      <Button
        variant="outlined"
        onClick={() =>
          checkUserPermission(
            PERMISSIONS.SEND_BUDGET_TO_PAYER,
            PERMISSIONS_ACTIONS.ADD,
            handleSendBudgetByEmail
          )
        }
        isLoading={isEmailLoading}
      >
        <Svg className={styles.icon} type={ICON.SEND} /> Enviar por e-mail
      </Button>

      {isNotRevised && (
        <Toast
          draggable
          open={isNotRevised}
          autoHideDuration={2000}
          onClose={handleIsNotRevisedModal}
        >
          <Alert
            severity="error"
            title="Você não pode enviar este orçamento porque ele não está revisado. Marque o orçamento como revisado e repita a operação."
            onClose={handleIsNotRevisedModal}
          />
        </Toast>
      )}
      {isEmptyFields && (
        <WarningModal title="Enviar por e-mail" handleModal={handleIsEmptyModal}>
          Não foi digitado o &quot;Email do responsável pelo pagamento&quot;. Clique em &quot;Dados
          do pagador&quot; e digite esta informação.
        </WarningModal>
      )}
      {isRefused && (
        <ConfirmModal
          handleModal={handleIsRefusedModal}
          handleSubmit={handleSendRefusedBudgetByEmail}
        >
          Este orçamento está recusado. Você tem certeza que ainda assim deseja enviar este
          orçamento ao pagador?
        </ConfirmModal>
      )}
    </>
  )
}

export default SendBudgetByEmailButton
