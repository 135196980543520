import React, { useMemo } from 'react'
import { SvgIcon, Badge, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TickCircleIcon from '_assets/icons/ic-tick-circle.svg'
import HandShakeIcon from '_assets/icons/ic-hand-shake.svg'
import CloseIcon from '_assets/icons/ic-close.svg'
import DuplicateIcon from '_assets/icons/ic-duplicate.svg'
import MessageIcon from '_assets/icons/ic-message.svg'
import SendIcon from '_assets/icons/ic-send.svg'
import NotificationIcon from '_assets/icons/ic-notification.svg'
import ArrowIcon from '_assets/icons/ic-arrow.svg'
import EditIcon from '_assets/icons/ic-edit.svg'
import TransactionIcon from '_assets/icons/ic-transaction.svg'
import BudgetIcon from '_assets/icons/ic-budget.svg'
import PlusIcon from '_assets/icons/ic-plus.svg'
import BloomIcon from '_assets/icons/ic-bloom.svg'
import TrophyIcon from '_assets/icons/ic-trophy.svg'
import ArrowBackIcon from '_assets/icons/ic-arrow-back.svg'
import ArrowRightIcon from '_assets/svgs/small-arrow.svg'
import CheckMarkIcon from '_assets/icons/ic-check-mark.svg'
import ArrowDotedIcon from '_assets/icons/ic-arrow-doted.svg'
import DocEditIcon from '_assets/icons/ic-doc-edit.svg'
import ProfileIcon from '_assets/icons/ic-profile.svg'
import ClockIcon from '_assets/icons/ic-clock.svg'
import Clock from '_assets/icons/clock.svg'
import AttachedPictureIcon from '_assets/icons/ic-attach-picture.svg'
import DocIcon from '_assets/icons/ic-doc.svg'
import ToolsIcon from '_assets/icons/ic-tools.svg'
import ListIcon from '_assets/icons/ic-list.svg'
import WarningIcon from '_assets/icons/ic-warning.svg'
import DottedLineIcon from '_assets/icons/ic-dotted-line.svg'
import ReplacePersonIcon from '_assets/icons/ic-replace-person.svg'
import PDFIcon from '_assets/icons/ic-pdf.svg'
import HandOkIcon from '_assets/icons/ic-hand-ok.svg'
import FlagIcon from '_assets/icons/ic-flag.svg'
import EmailIcon from '_assets/icons/ic-email.svg'
import HistoryIcon from '_assets/icons/ic-history.svg'
import DownloadIcon from '_assets/icons/ic-download.svg'
import RefreshIcon from '_assets/icons/ic-refresh.svg'
import KeyIcon from '_assets/icons/ic-key.svg'
import DeniedCanceledIcon from '_assets/icons/ic-denied-canceled.svg'
import DeniedIcon from '_assets/icons/ic-denied.svg'
import CloseBorderedIcon from '_assets/icons/ic-close-bordered.svg'
import ForbiddenIcon from '_assets/icons/ic-forbidden.svg'
import BudgetDeniedIcon from '_assets/icons/ic-budget-denied.svg'
import DislikeIcon from '_assets/icons/ic-dislike.svg'
import ReviewCanceledIcon from '_assets/icons/ic-review-canceled.svg'
import CheckIcon from '_assets/icons/ic-check.svg'
import RescheduledVisitIcon from '_assets/icons/ic-rescheduled-visit.svg'
import ReopenServiceOrderIcon from '_assets/icons/ic-reopen-service-order.svg'
import ScheduledVisitIcon from '_assets/icons/ic-scheduled-visit.svg'
import BudgetSentIcon from '_assets/icons/ic-budget-sent.svg'
import BudgetAprovedIcon from '_assets/icons/ic-budget-aproved.svg'
import BudgetDeniedPayerIcon from '_assets/icons/ic-budget-denied-payer.svg'
import ExecutionIcon from '_assets/icons/ic-execution.svg'
import WorkInProgressIcon from '_assets/icons/ic-work-in-progress.svg'
import CallCompletedIcon from '_assets/icons/ic-call-completed-successfully.svg'
import HourglassIcon from '_assets/icons/ic-hourglass.svg'
import CommentIcon from '_assets/icons/ic-document-text.svg'
import BookIcon from '_assets/icons/ic-book.svg'
import NotesIcon from '_assets/icons/ic-notes.svg'
import InfoCircleIcon from '_assets/icons/ic-info-circle.svg'
import CloseCircleIcon from '_assets/icons/ic-close-circle.svg'
import StarIcon from '_assets/icons/ic-star.svg'
import AlarmIcon from '_assets/icons/ic-alarm.svg'
import HandShakeCanceledIcon from '_assets/icons/ic-hand-shake-canceled.svg'
import RacingFlagIcon from '_assets/icons/ic-racing-flag.svg'
import RacingFlagCanceledIcon from '_assets/icons/ic-racing-flag-canceled.svg'
import ServiceFinishIcon from '_assets/icons/ic-service-finish.svg'
import ServiceCanceledIcon from '_assets/icons/ic-service-canceled.svg'
import OpenProposeIcon from '_assets/icons/ic-propose.svg'
import DocumentCanceledIcon from '_assets/icons/ic-document-canceled.svg'
import ApproveApplicantValuationIcon from '_assets/icons/ic-approve-applicant-valuation.svg'
import ReproveApplicantValuationIcon from '_assets/icons/ic-reprove-applicant-valuation.svg'
import DocumentForwardIcon from '_assets/icons/ic-document-forward.svg'
import ReactivateIcon from '_assets/icons/ic-reactivate.svg'
import QuestionIcon from '_assets/icons/ic-question-circle.svg'
import ProfileDeleted from '_assets/icons/ic-profile-delete.svg'
import DocumentDownload from '_assets/icons/ic-document-download.svg'
import AddCircle from '_assets/icons/ic-add-circle.svg'
import RemoveCircle from '_assets/icons/ic-remove.svg'
import ClipboardTick from '_assets/icons/ic-clipboard-tick.svg'
import TicketDiscount from '_assets/icons/ic-ticket-discount.svg'
import InfoCircleIBlue from '_assets/icons/ic-info-circle-blue.svg'
import ShieldTick from '_assets/icons/ic-shield-tick.svg'
import DragAndDropIcon from '_assets/svgs/drag-and-drop.svg'
import LikeIcon from '_assets/icons/ic-like.svg'
import WhatsappIcon from '_assets/icons/whatsapp.svg'
import DirectboxNotif from '_assets/icons/directbox-notif.svg'
import TradesmanSequenceCreated from '_assets/icons/ic-tradesman-sequence-created.svg'
import SequenceTradesmanChanged from '_assets/icons/ic-sequence-tradesman-changed.svg'
import NewLike from '_assets/icons/ic-new-like.svg'
import NewDislike from '_assets/icons/ic-new-dislike.svg'
import LinkSent from '_assets/icons/ic-link-sent.svg'
import Triage from '_assets/icons/ic-triage.svg'
import Owner from '_assets/icons/ic-user-search.svg'
import Calculator from '_assets/icons/ic-calculator.svg'
import ClipboardDenied from '_assets/icons/ic-clipboard-denied.svg'
import SendEmail from '_assets/icons/ic-send-email.svg'

import useStyles from './styles'

export const ICON = {
  CLOSE: 'close',
  LIKE: 'like',
  DUPLICATE: 'duplicate',
  MESSAGE: 'message',
  SEND: 'send',
  NOTIFICATION: 'notification',
  ARROW: 'arrow',
  EDIT: 'edit',
  TRANSACTION: 'transaction',
  BUDGET: 'budget',
  PLUS: 'plus',
  BLOOM: 'bloom',
  TROPHY: 'trophy',
  ARROW_BACK: 'arrowBack',
  CHECK_MARK: 'checkMark',
  ARROW_DOTED: 'arrowDoted',
  ARROW_RIGHT: 'arrowRight',
  DOC_EDIT: 'docEdit',
  PROFILE: 'profile',
  CLOCK: 'clock',
  SIMPLE_CLOCK: 'simple_clock',
  ATTACHED_PICTURE: 'attachedPicture',
  DOC: 'doc',
  TOOLS: 'tools',
  LIST: 'list',
  WARNING: 'warning',
  DOTTED_LINE: 'dottedLine',
  REPLACE_PERSON: 'replacePerson',
  CHECK: 'check',
  HAND_SHAKE: 'handShake',
  PDF: 'pdf',
  HAND_OK: 'handOk',
  FLAG: 'flag',
  EMAIL: 'email',
  HISTORY: 'history',
  DOWNLOAD: 'download',
  REFRESH: 'refresh',
  KEY: 'key',
  DENIED_CANCELED: 'deniedCanceled',
  DENIED: 'denied',
  CLOSE_BORDERED: 'closeBordered',
  FORBIDDEN: 'forbidden',
  BUDGET_DENIED: 'budgetDenied',
  DISLIKE: 'dislike',
  REVIEW_CANCELED: 'reviewCanceled',
  RESCHEDULED_VISIT: 'rescheduledVisit',
  SCHEDULED_VISIT: 'scheduledVisit',
  REOPEN_SERVICE_ORDER: 'reopenServiceOrder',
  BUDGET_SENT: 'moneySend',
  BUDGET_APROVED: 'dollarSquare',
  BUDGET_DENIED_PAYER: 'dollarRemove',
  EXECUTION: 'execution',
  WORK_IN_PROGRESS: 'workInProgress',
  CALL_COMPLETED: 'callCompleted',
  HOURGLASS: 'hourglass',
  DOCUMENT_TEXT: 'documentText',
  NOTES: 'notes',
  NOTES_GRAY: 'noteGray',
  INFO_CIRCLE: 'infoCircle',
  INFO_CIRCLE_BLUE: 'infoCircleBlue',
  CLOSE_CIRCLE: 'closeCircle',
  TICK_CIRCLE: 'tickCircle',
  STAR: 'star',
  ALARM: 'alarm',
  HAND_SHAKE_CANCELED: 'handShakeCanceled',
  RACING_FLAG_CANCELED: 'racingFlagCanceled',
  SERVICE_FINISH: 'serviceFinish',
  SERVICE_CANCELED: 'serviceCanceled',
  OPEN_PROPOSE: 'propose',
  DOCUMENT_CANCELED: 'documentCanceled',
  APPROVE_APPLICANT_VALAUTION: 'approveApplicantValuation',
  REPROVE_APPLICANT_VALAUTION: 'reproveApplicantValuation',
  DOCUMENT_FORWARD: 'documentForward',
  REACTIVATE: 'reactivate',
  QUESTION: 'question',
  DOCUMENT_DOWNLOAD: 'documentDownload',
  PROFILE_DELETED: 'profileDeleted',
  ADD_CIRCLE: 'addCircle',
  REMOVE_CIRCLE: 'removeCircle',
  RACING_FLAG: 'racingFlag',
  CLIPBOARD_TICK: 'clipboardTick',
  TICKET_DISCOUNT: 'ticketDiscount',
  SHIELD_TICK: 'shieldTick',
  TRADESMAN_SEQUENCE_CREATED: 'TradesmanSequenceCreated',
  DRAG_AND_DROP: 'dragAndDrop',
  WHATSAPP: 'whatsapp',
  DIRECT_BOX_NOTIF: 'directboxNotif',
  SEQUENCE_TRADESMAN_CHANGED: 'sequenceTradesmanChanged',
  NEW_LIKE: 'newLike',
  NEW_DISLIKE: 'newDislike',
  LINK_SENT: 'linkSent',
  TRIAGE: 'triage',
  OWNER: 'owner',
  CALCULATOR: 'calculator',
  CLIPBOARD_DENIED: 'clipboardDenied',
  SEND_EMAIL: 'sendEmail',
}

const ICON_OPTIONS = {
  [ICON.CLOSE]: {
    icon: CloseIcon,
    ariaLabel: 'Fechar',
  },
  [ICON.LIKE]: {
    icon: LikeIcon,
    ariaLabel: 'Like',
  },
  [ICON.DUPLICATE]: {
    icon: DuplicateIcon,
    ariaLabel: 'Duplicar ação',
  },
  [ICON.MESSAGE]: {
    icon: MessageIcon,
    ariaLabel: 'Ver mensagens',
  },
  [ICON.SEND]: {
    icon: SendIcon,
    ariaLabel: 'Enviar',
  },
  [ICON.NOTIFICATION]: {
    icon: NotificationIcon,
    ariaLabel: 'Notificação',
  },
  [ICON.ARROW]: {
    icon: ArrowIcon,
    ariaLabel: 'Expandir informações',
  },
  [ICON.ARROW_RIGHT]: {
    icon: ArrowRightIcon,
    ariaLabel: 'Próximo',
  },
  [ICON.EDIT]: {
    icon: EditIcon,
    ariaLabel: 'Editar informações',
  },
  [ICON.TRANSACTION]: {
    icon: TransactionIcon,
    ariaLabel: 'Orçamento negociação',
  },
  [ICON.BUDGET]: {
    icon: BudgetIcon,
    ariaLabel: 'Orçamento',
  },
  [ICON.PLUS]: {
    icon: PlusIcon,
  },
  [ICON.BUDGET_SENT]: {
    icon: BudgetSentIcon,
    ariaLabel: 'Orçamento enviado ao pagador',
  },
  [ICON.BUDGET_APROVED]: {
    icon: BudgetAprovedIcon,
    ariaLabel: 'Orçamento aprovado pelo pagador',
  },
  [ICON.TICKET_DISCOUNT]: {
    icon: TicketDiscount,
  },
  [ICON.BUDGET_DENIED_PAYER]: {
    icon: BudgetDeniedPayerIcon,
    ariaLabel: 'Orçamento negado pelo pagador',
  },
  [ICON.BLOOM]: {
    icon: BloomIcon,
    ariaLabel: '',
  },
  [ICON.PLUS]: {
    icon: PlusIcon,
  },
  [ICON.TROPHY]: {
    icon: TrophyIcon,
  },
  [ICON.ARROW_BACK]: {
    icon: ArrowBackIcon,
  },
  [ICON.CHECK_MARK]: {
    icon: CheckMarkIcon,
  },
  [ICON.ARROW_DOTED]: {
    icon: ArrowDotedIcon,
  },
  [ICON.DOC_EDIT]: {
    icon: DocEditIcon,
  },
  [ICON.PROFILE]: {
    icon: ProfileIcon,
  },
  [ICON.CLOCK]: {
    icon: ClockIcon,
  },
  [ICON.SIMPLE_CLOCK]: {
    icon: Clock,
  },
  [ICON.ATTACHED_PICTURE]: {
    icon: AttachedPictureIcon,
  },
  [ICON.DOC]: {
    icon: DocIcon,
  },
  [ICON.TOOLS]: {
    icon: ToolsIcon,
  },
  [ICON.LIST]: {
    icon: ListIcon,
  },
  [ICON.WARNING]: {
    icon: WarningIcon,
  },
  [ICON.DOTTED_LINE]: {
    icon: DottedLineIcon,
  },
  [ICON.REPLACE_PERSON]: {
    icon: ReplacePersonIcon,
  },
  [ICON.CHECK]: {
    icon: CheckIcon,
  },
  [ICON.HAND_SHAKE]: {
    icon: HandShakeIcon,
  },
  [ICON.PDF]: {
    icon: PDFIcon,
  },
  [ICON.HAND_OK]: {
    icon: HandOkIcon,
  },
  [ICON.FLAG]: {
    icon: FlagIcon,
  },
  [ICON.EMAIL]: {
    icon: EmailIcon,
    ariaLabel: 'Marcar como não lido',
  },
  [ICON.HISTORY]: {
    icon: HistoryIcon,
  },
  [ICON.DOWNLOAD]: {
    icon: DownloadIcon,
  },
  [ICON.REFRESH]: {
    icon: RefreshIcon,
  },
  [ICON.KEY]: {
    icon: KeyIcon,
  },
  [ICON.DENIED_CANCELED]: {
    icon: DeniedCanceledIcon,
  },
  [ICON.DENIED]: {
    icon: DeniedIcon,
  },
  [ICON.CLOSE_BORDERED]: {
    icon: CloseBorderedIcon,
  },
  [ICON.FORBIDDEN]: {
    icon: ForbiddenIcon,
  },
  [ICON.BUDGET_DENIED]: {
    icon: BudgetDeniedIcon,
  },
  [ICON.DISLIKE]: {
    icon: DislikeIcon,
  },
  [ICON.REVIEW_CANCELED]: {
    icon: ReviewCanceledIcon,
  },
  [ICON.RESCHEDULED_VISIT]: {
    icon: RescheduledVisitIcon,
  },
  [ICON.REOPEN_SERVICE_ORDER]: {
    icon: ReopenServiceOrderIcon,
  },
  [ICON.SCHEDULED_VISIT]: {
    icon: ScheduledVisitIcon,
  },
  [ICON.EXECUTION]: {
    icon: ExecutionIcon,
    ariaLabel: 'Execução prestador',
  },
  [ICON.WORK_IN_PROGRESS]: {
    icon: WorkInProgressIcon,
    ariaLabel: 'Serviço em andamento',
  },
  [ICON.CALL_COMPLETED]: {
    icon: CallCompletedIcon,
    ariaLabel: 'Chamado concluído com sucesso',
  },
  [ICON.HOURGLASS]: {
    icon: HourglassIcon,
  },
  [ICON.DOCUMENT_TEXT]: {
    icon: CommentIcon,
    ariaLabel: 'Observações',
  },
  [ICON.NOTES]: {
    icon: BookIcon,
    ariaLabel: 'Anotações',
  },
  [ICON.NOTES_GRAY]: {
    icon: NotesIcon,
    ariaLabel: 'Anotações',
  },
  [ICON.INFO_CIRCLE]: {
    icon: InfoCircleIcon,
  },
  [ICON.INFO_CIRCLE_BLUE]: {
    icon: InfoCircleIBlue,
  },
  [ICON.CLOSE_CIRCLE]: {
    icon: CloseCircleIcon,
  },
  [ICON.TICK_CIRCLE]: {
    icon: TickCircleIcon,
  },
  [ICON.STAR]: {
    icon: StarIcon,
  },
  [ICON.ALARM]: {
    icon: AlarmIcon,
  },
  [ICON.HAND_SHAKE_CANCELED]: {
    icon: HandShakeCanceledIcon,
  },
  [ICON.RACING_FLAG_CANCELED]: {
    icon: RacingFlagCanceledIcon,
  },
  [ICON.RACING_FLAG]: {
    icon: RacingFlagIcon,
  },
  [ICON.SERVICE_FINISH]: {
    icon: ServiceFinishIcon,
    ariaLabel: 'Serviço finalizado',
  },
  [ICON.SERVICE_CANCELED]: {
    icon: ServiceCanceledIcon,
    ariaLabel: 'Finalização do serviço cancelada',
  },
  [ICON.SERVICE_CANCELED]: {
    icon: ServiceCanceledIcon,
    ariaLabel: 'Finalização do serviço cancelada',
  },
  [ICON.OPEN_PROPOSE]: {
    icon: OpenProposeIcon,
  },
  [ICON.DOCUMENT_CANCELED]: {
    icon: DocumentCanceledIcon,
  },
  [ICON.APPROVE_APPLICANT_VALAUTION]: {
    icon: ApproveApplicantValuationIcon,
  },
  [ICON.REPROVE_APPLICANT_VALAUTION]: {
    icon: ReproveApplicantValuationIcon,
  },
  [ICON.DOCUMENT_FORWARD]: {
    icon: DocumentForwardIcon,
  },
  [ICON.REACTIVATE]: {
    icon: ReactivateIcon,
  },
  [ICON.QUESTION]: {
    icon: QuestionIcon,
  },
  [ICON.DOCUMENT_DOWNLOAD]: {
    icon: DocumentDownload,
  },
  [ICON.PROFILE_DELETED]: {
    icon: ProfileDeleted,
  },
  [ICON.ADD_CIRCLE]: {
    icon: AddCircle,
  },
  [ICON.REMOVE_CIRCLE]: {
    icon: RemoveCircle,
  },
  [ICON.CLIPBOARD_TICK]: {
    icon: ClipboardTick,
  },
  [ICON.SHIELD_TICK]: {
    icon: ShieldTick,
  },
  [ICON.TRADESMAN_SEQUENCE_CREATED]: {
    icon: TradesmanSequenceCreated,
  },
  [ICON.DRAG_AND_DROP]: {
    icon: DragAndDropIcon,
    ariaLabel: 'Arraste para mover',
  },
  [ICON.WHATSAPP]: {
    icon: WhatsappIcon,
  },
  [ICON.DIRECT_BOX_NOTIF]: {
    icon: DirectboxNotif,
  },
  [ICON.SEQUENCE_TRADESMAN_CHANGED]: {
    icon: SequenceTradesmanChanged,
  },
  [ICON.NEW_LIKE]: {
    icon: NewLike,
  },
  [ICON.NEW_DISLIKE]: {
    icon: NewDislike,
  },
  [ICON.LINK_SENT]: {
    icon: LinkSent,
  },
  [ICON.TRIAGE]: {
    icon: Triage,
  },
  [ICON.OWNER]: {
    icon: Owner,
  },
  [ICON.CALCULATOR]: {
    icon: Calculator,
  },
  [ICON.CLIPBOARD_DENIED]: {
    icon: ClipboardDenied,
  },
  [ICON.SEND_EMAIL]: {
    icon: SendEmail,
  },
}

const Svg = ({ icon, ariaLabel, type, badgeContent, className, tooltip, ...props }) => {
  const styles = useStyles()

  const renderSvg = useMemo(() => {
    const content = type ? ICON_OPTIONS[type].icon : icon
    const ariaLabelContent = type ? ICON_OPTIONS[type].ariaLabel : ariaLabel

    return (
      <SvgIcon
        aria-label={ariaLabelContent}
        aria-hidden={!ariaLabelContent}
        viewBox={content.viewBox}
        className={classnames({
          [className]: className.length,
          [styles.default]: !className.length,
        })}
        {...props}
      >
        <use xlinkHref={`#${content.id}`} />
      </SvgIcon>
    )
  }, [ariaLabel, className, icon, props, styles.default, type])

  if (tooltip) {
    return <Tooltip title={tooltip}>{renderSvg}</Tooltip>
  }
  if (badgeContent) {
    return (
      <Badge badgeContent={badgeContent} color="error">
        {renderSvg}
      </Badge>
    )
  }
  return renderSvg
}

Svg.propTypes = {
  type: PropTypes.oneOf([...Object.values(ICON), '']),
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      viewBox: PropTypes.string,
      id: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
}

Svg.defaultProps = {
  ariaLabel: '',
  type: '',
  className: '',
  icon: '',
}

export default React.memo(Svg)
