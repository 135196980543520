import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Alert, Button, Loader, Modal, Typography, Toast } from '@refera/ui-web'
import { useDispatch, useSelector } from 'react-redux'

import { companySelector } from '_modules/company/selector'
import { updateCompany, UPDATE_COMPANY } from '_modules/company/actions'
import { financeOptionsSelector } from '_modules/finance/selectors'
import { getFinanceOptions, GET_FINANCE_OPTIONS } from '_modules/finance/actions'
import useFetchCall from '_hooks/use-fetch-call'
import Select from '_components/common/select'

import useStyles from './styles'

const RequestTaxRegimeModal = ({ open, closeModal }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [selectedTax, setSelectedTax] = useState()
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })
  const taxRegimeOptions = useSelector(financeOptionsSelector)?.taxRegimes
  const company = useSelector(companySelector)

  const [isLoadingFinanceOptions] = useFetchCall(GET_FINANCE_OPTIONS.ACTION)

  const selectOptions = useMemo(() => {
    if (!taxRegimeOptions?.length) {
      return null
    }

    return [{ value: '', label: 'Selecione' }, ...taxRegimeOptions]
  }, [taxRegimeOptions])

  const handleCloseToast = useCallback(() => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }, [])

  const handleSelectChange = useCallback(event => {
    setSelectedTax(event.target.value)
  }, [])

  const handleSuccess = useCallback(() => {
    setToast({
      isOpen: true,
      message: 'Registro salvo com sucesso.',
      type: 'success',
    })
    closeModal()
  }, [closeModal])

  const handleError = useCallback(() => {
    setToast({
      isOpen: true,
      message: 'Ocorreu um erro ao salvar o registro.',
      type: 'error',
    })
  }, [])

  const handleSubmit = useCallback(() => {
    dispatch(updateCompany(company.id, { taxRegime: selectedTax }))
  }, [company.id, dispatch, selectedTax])

  const [isLoading] = useFetchCall(UPDATE_COMPANY.ACTION, handleSuccess, handleError)

  useEffect(() => {
    if (!taxRegimeOptions.length && !isLoadingFinanceOptions) {
      dispatch(getFinanceOptions())
    }
  }, [taxRegimeOptions])

  return (
    <>
      <Loader hasBackdrop open={isLoading} label="Aguarde..." />
      {open && (
        <Modal
          className={styles.modal}
          title="Atualização de cadastro"
          onBackdropClick={closeModal}
          onClose={closeModal}
          open={open}
          actionsButtons={
            <Grid className={styles.modalButtons}>
              <Button variant="ghost" onClick={closeModal}>
                Me lembre mais tarde
              </Button>
              <Button
                disabled={!selectedTax}
                color="primary"
                variant="primary"
                onClick={handleSubmit}
              >
                Confirmar
              </Button>
            </Grid>
          }
        >
          <Typography className={styles.textlabel}>
            Prestador, pedimos que informe os dados abaixo, para que possamos manter seu cadastro
            atualizado.
          </Typography>
          <Select
            label="Regime tributário"
            defaultValue=""
            value={selectedTax}
            onChange={handleSelectChange}
            options={selectOptions}
          />
        </Modal>
      )}
      {toast.isOpen && (
        <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
        </Toast>
      )}
    </>
  )
}

RequestTaxRegimeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default RequestTaxRegimeModal
