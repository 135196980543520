import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { TextField } from '@mui/material'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Modal, Button, Toast, Alert } from '@refera/ui-web'
import moment from 'moment'

import { getServiceOrder, updateAppointmentDateSchedule } from '_modules/service-orders/actions'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { userSelector } from '_/modules/authentication/selectors'
import useToggle from '_hooks/use-toggle'

import useStyles from './styles'
import { getStepStatusLog } from '_/modules/budget/actions'

// TODO: Add react-hook-form to handle form
const AcceptanceModal = ({ open, onCancel, checkOption }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const user = useSelector(userSelector)

  const [selectedTime, setSelectedTime] = useState(null)
  const [isToastOpen, toggleToastOpen] = useToggle()
  const [error, setError] = useState(false)

  const suggestionDate = useMemo(() => {
    if (checkOption === 'first_suggestion_date') {
      return serviceOrder?.firstSuggestionDate
    }

    if (checkOption === 'second_suggestion_date') {
      return serviceOrder?.secondSuggestionDate
    }

    if (checkOption === 'third_suggestion_date') {
      return serviceOrder?.thirdSuggestionDate
    }

    return null
  }, [
    checkOption,
    serviceOrder?.firstSuggestionDate,
    serviceOrder?.secondSuggestionDate,
    serviceOrder?.thirdSuggestionDate,
  ])

  const suggestionStartTime = useMemo(() => {
    if (checkOption === 'first_suggestion_date') {
      return serviceOrder?.firstStartTime
    }

    if (checkOption === 'second_suggestion_date') {
      return serviceOrder?.secondStartTime
    }

    if (checkOption === 'third_suggestion_date') {
      return serviceOrder?.thirdStartTime
    }

    return null
  }, [
    checkOption,
    serviceOrder?.firstStartTime,
    serviceOrder?.secondStartTime,
    serviceOrder?.thirdStartTime,
  ])

  const suggestionEndTime = useMemo(() => {
    if (checkOption === 'first_suggestion_date') {
      return serviceOrder?.firstEndTime
    }

    if (checkOption === 'second_suggestion_date') {
      return serviceOrder?.secondEndTime
    }

    if (checkOption === 'third_suggestion_date') {
      return serviceOrder?.thirdEndTime
    }

    return null
  }, [
    checkOption,
    serviceOrder?.firstEndTime,
    serviceOrder?.secondEndTime,
    serviceOrder?.thirdEndTime,
  ])

  const whatsAppMessage = useMemo(() => {
    const agency = `${serviceOrder?.agency.get('name')}`
    const address = `${serviceOrder.property.get('address')}`
    const number = `${serviceOrder.property.get('number')}`
    const cep = `${serviceOrder.property.get('cep')}`
    const neighborhood = `${serviceOrder.property.get('neighborhood')}`
    const city = `${serviceOrder.property.get('city')}`
    const endereco = `${address}, ${number}, ${cep}, ${neighborhood}, ${city}`

    return (
      `Olá, sou o prestador ${user.toJS().companyName}, parceiro da Refera\n\n` +
      `Estou entrando em contato com você para informar que farei a visita para realizar o orçamento do chamado ${
        serviceOrder?.id
      } em ${moment(suggestionDate).format('DD/MM/yyyy')} às ${moment(selectedTime).format(
        'HH:mm'
      )}.\n` +
      `No imóvel localizado em: ${endereco}\n` +
      `Esse chamado está vinculado a imobiliária ${agency}.`
    )
  }, [serviceOrder, selectedTime, suggestionDate])

  const sendWhatsApp = useCallback(() => {
    const encodedText = window.encodeURIComponent(whatsAppMessage)
    const phone = `${serviceOrder?.contactNumber?.toString().slice(1)}`

    window.open(`https://web.whatsapp.com/send?phone=${phone}&text=${encodedText}`, '_blank')
  }, [serviceOrder?.contactNumber, suggestionDate, whatsAppMessage])

  const handleSubmit = useCallback(() => {
    if (error || !moment(selectedTime).isValid()) {
      return
    }

    const dataApp = {
      dateAndTime: moment(suggestionDate + selectedTime.toISOString().slice(10)).format(),
      isAcceptance: true,
    }
    dispatch(updateAppointmentDateSchedule(serviceOrder?.id, dataApp)).then(() => {
      dispatch(getServiceOrder(serviceOrder?.id))
      dispatch(getStepStatusLog(serviceOrder?.id))
      handleUpdateAppointmentSuccess()
    })
  }, [dispatch, error, selectedTime, serviceOrder?.id, suggestionDate])

  const handleTimePickerError = useCallback(reason => {
    setError(['maxTime', 'minTime'].includes(reason))
  }, [])

  const renderTimePickerInput = useCallback(
    params => {
      const helperText = error ? (
        <Typography className={styles.helperText}>Conteúdo inválido</Typography>
      ) : undefined

      return (
        <TextField
          variant="standard"
          placeholder="HH:MM"
          className={styles.input}
          error={error}
          helperText={helperText}
          fullWidth
          {...params}
        />
      )
    },
    [error, styles.helperText, styles.input]
  )

  const handleUpdateAppointmentSuccess = useCallback(() => {
    toggleToastOpen()
    sendWhatsApp()
    onCancel()
  }, [onCancel, sendWhatsApp, toggleToastOpen])

  return (
    <Modal
      className={styles.modal}
      title="Informe um horário"
      onBackdropClick={onCancel}
      onClose={onCancel}
      open={open}
      actionsButtons={
        <Grid className={styles.modalButtons}>
          <Button color="red" variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            disabled={error || !moment(selectedTime).isValid()}
            color="primary"
            variant="primary"
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </Grid>
      }
    >
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt">
        <Grid className={styles.modalContent}>
          <Typography className={styles.text}>
            Informe o horário exato que você estará visitando o cliente entre o período de{' '}
            {suggestionStartTime?.toString()?.substring(0, 5)} e{' '}
            {suggestionEndTime?.toString()?.substring(0, 5)} do dia{' '}
            {moment(suggestionDate).format('DD/MM/yyyy')}. Após confirmar, envie uma mensagem via
            WhatsApp para o cliente.
          </Typography>
          <TimePicker
            ampm={false}
            value={selectedTime}
            onChange={setSelectedTime}
            onError={handleTimePickerError}
            renderInput={renderTimePickerInput}
            minTime={moment(suggestionStartTime, 'HH:mm')}
            maxTime={moment(suggestionEndTime, 'HH:mm')}
          />
        </Grid>

        <Toast draggable open={isToastOpen} autoHideDuration={6000} onClose={toggleToastOpen}>
          <Alert severity="success" title="Ação executada com sucesso." onClose={toggleToastOpen} />
        </Toast>
      </LocalizationProvider>
    </Modal>
  )
}

export default AcceptanceModal
